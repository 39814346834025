import styled from "styled-components";
import BaseWrapper from "../common/BaseArea";
import CSSGlobalVars from "../../../utils/CSSGlobalVars";
import { useEffect, useState } from "react";
import logo_round from '../../../assets/images/gallery/logo_postcard.png'
import stamp from '../../../assets/images/gallery/stamp.png'
import qr from '../../../assets/images/gallery/qr.png'
import recycling from '../../../assets/images/gallery/recycling.png'
import { randomFloat } from "../../../utils/Utils";





const Wrapper = styled(BaseWrapper)`

    display: grid;
    justify-items: center;
    align-items: center;

`

const Postcard = styled.div`
    width: 70%;
    min-width: ${CSSGlobalVars.phoneCap * 0.8}px;
    aspect-ratio: 16 / 10;

    border: 3px solid #3250ED;

    display: grid;
    grid-template-rows: 20% auto 20%;

    background-color: white;
    padding: 20px;

    font-family: IBM Plex Mono;

    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
        width: 70%;
        min-width: 30%;
        aspect-ratio: 10 / 16;
        grid-template-rows: 15% auto 20%;
    }

    .header{
        width: 100%;
        height: 100%;

        display: grid;
        grid-template-columns: 20% 30% 50%;
        justify-items: left;
        font-family: Tiposka;
        .postcard-number{
            margin-top: 10px;
        }
        
        .logo-postcard{
            height: 100px;
            @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
                height: 50px;
            }
        }
        .stamp{
            height: 100px;
            justify-self: right;
            @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
                height: 50px;
            }
        }

    }

    .body{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 50% 50%;

        @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
            grid-template-columns: 100%;
            grid-template-rows: 50% 50%;
        }

        .content{
            padding: 10px;
            @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
                padding: 0px;
            }

            p{
                width: 90%;
                text-align: left;
                font-size: 16px;
                text-align: left;

                @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
                    font-size: 12px;
                    width: 100%;
                }

            }
        }

        .postcard-image{
            
            display: none;
            @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
                width: 100%;
                display: block;
                

            }
        }

        .postcard-details
        {
            width: 70%;
            display: flex;
            gap: 20px;
            flex-direction: column;
            justify-items: start;
            align-items: start;
            text-align: left;

            .postcard-details-number{
                font-size: 22px;
            }

            @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
                   display: none;
            }
        }
    }

    .footer{
        width: 100%;
        height: 100%;

        display: grid;
        grid-template-columns: 50% 50%; 

        .postcard-footer-left{
            display: grid;
            grid-template-columns: 20% 80%;
            align-items: center;
            .postcard-qr{
                width: 90%;
            }
            .postcard-footer-left-content{
                text-align: left;
                font-size: 10px;
            }
        }

        .postcard-footer-right{
            display: grid;
            grid-template-columns: 20% 80%;
            align-items: center;
            .postcard-recycling{
                width: 40%;
                margin-right: 10px;
                justify-self: right;
            }
            .postcard-footer-right-content{
                text-align: left;
                font-size: 10px;
            }
        }
        
        @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
            display: none;
        }
    }

`


const Gallery = ({postcards})=>{

    const [currentPostcard , setCurrentpostcar] = useState(0);

    useEffect(()=>{
        if(postcards.length > 0)
            setCurrentpostcar(Math.floor(randomFloat(0,postcards.length - 1)));

    }, [postcards])

    const getPostcardNumber = ()=>{

        if(postcards.length === 0)return '';

        return `/${postcards[currentPostcard].number}`
    }

    const getPostcardContent = ()=>{
        if(postcards.length === 0)return '';

        return `${postcards[currentPostcard].message}`
    }
    const getPostcardImage = ()=>{
        if(postcards.length === 0)return '';

        return `${postcards[currentPostcard].photoUrl}`
    }

    return(
        <Wrapper>
            <Postcard>
                <div className="header">
                    <p className="postcard-number">{getPostcardNumber()}</p>
                    <img className="logo-postcard" src={logo_round} alt="logo postcard"></img>
                    <img className="stamp" src={stamp} alt="stamp"></img>
                </div>
                <div className="body">
                    <div className="content">
                        <p>{getPostcardContent()}</p>
                    </div>
                    <img className="postcard-image" src={getPostcardImage()} alt="postcard"></img>
                    <div className="postcard-details">
                        <p className="postcard-details-number">00000/0000</p>
                        <p>Dear Friend</p>
                        <p>Lorem upsum dolor Sit Amet Consecteteur Sed Mi Curabitur elit</p>
                        <p>Return Address</p>
                    </div>
                </div>
                <div className="footer">
                    <div className="postcard-footer-left">
                        <img className="postcard-qr" src={qr} alt="qr code"></img>
                        <p className="postcard-footer-left-content">With love ai is a project by Pedro Garlaschi, to explore ways of humanising A.I. Visit www.withloveai.co.uk or scan the QR code to find out more.</p>
                    </div>
                    <div className="postcard-footer-right">
                        <img className="postcard-recycling" src={recycling} alt="reciclying"></img>
                        <p className="postcard-footer-right-content">When you have enjoyed me, stuck me to your fridge for a while and showed your friends, please recycle me.</p>
                    </div>
                </div>
            </Postcard>
        </Wrapper>
    )
}

export default Gallery;
