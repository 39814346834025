import styled from "styled-components";
import { LogoLeft, LogoRight } from "./Logo";
import CSSGlobalVars from "../../../utils/CSSGlobalVars";
import BaseWrapper from "../common/BaseArea";
import { useEffect, useState } from "react";
import { map } from "../../../utils/Utils";


const Wrapper = styled(BaseWrapper)`


    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    justify-items: center;
    align-content: center;

    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
  
        grid-template-columns: 100%;
        grid-template-rows: 50% 50%;

    }  
`
const Home = ({scrollPos})=>{

    let [scroll , setScroll] = useState(0);

    useEffect(()=>{


        setScroll(map(scrollPos , 0.015,0.25,0,1));
    },[scrollPos])

    return(
        <Wrapper>
            <LogoLeft scroll={scroll}/>
            <LogoRight scroll={scroll}/>
        </Wrapper>
    )
}

export default Home;
