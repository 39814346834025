import styled from "styled-components";
import BaseWrapper from "../common/BaseArea";
import CSSGlobalVars from "../../../utils/CSSGlobalVars";


const Wrapper = styled(BaseWrapper)`

    height: 300px;
    width: 100%;
    padding-left: 0%;

    background-color: rgba(245, 246, 255,1);

    display: grid;
    justify-items:center ;
    align-items: center;

`
const FormBox = styled.div`

    width: 50%;
    max-width: 800px;
    height: 50%;
    padding: 30px;
    

    background-color: #3250ED;    
    color: white;

    font-family: Tiposka;

    display: flex;

    flex-direction: column;
    align-items: start;
    justify-content: space-around;
    text-align: left;


    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
        width: 100%;
        height: 100%;
        padding: 0px;
    } 
    
    .header{
        font-size: 22px;

        @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
            width: 80%;
            margin-left: 10%;
        } 
       
    }

    .content{
        font-size:12px;
        @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
            width: 80%;
            margin-left: 10%;
        } 
    }

    .form-items{
        width: 100%;
        display: grid;
        grid-template-columns: 30% 20%;

        justify-items: left;
        align-items: center;
        text-align: left;

        @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
            margin-left: 10%;
            width: 80%;
            grid-template-columns: 60% 40%;
        } 

        input{
            width: 95%;
            height: 45px;
            padding-left: 10px;
            text-transform: uppercase;

            @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
                width: 98%;
                
            } 

        }

        .submit{
            background-color: #00F0FF;
            width: 80%;
            height: 45px;
            display: grid;
            justify-items: center;
            align-items: center;
            cursor: pointer;

            justify-self: center;

            @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
                width: 80%;
                justify-self: center;
            } 
        }
    }


`


const GetInvolved = ()=>{
    return(
        <Wrapper>
            <FormBox>
                <p className="header">Would you like to get involved?</p>
                <p className="content">If you’d like to receive a postcard (UK only), please enter your postcode below to find your address.</p>
                <div className="form-items">
                    <input type="text"/>
                    <p className="submit">Find</p>
                </div>
            </FormBox>
        </Wrapper>
    )
}

export default GetInvolved;
