import styled , {keyframes} from "styled-components";
import CSSGlobalVars from '../../../utils/CSSGlobalVars';


const BaseWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'leftPosition',  // Ensure `leftPosition` is not passed to the DOM
}).attrs((props) => ({
  style: {
    left: props.leftPosition || '0px',  // Apply `leftPosition` as inline style
  },
}))`
    display: flex;
    flex-direction: column;      
    justify-content: center;
    position: relative;
    width: 100%;

`;


const WrapperLeft = styled(BaseWrapper).attrs(props => ({
  style: {
    left: `${props.leftPosition}px`,  // Inline style to prevent class generation
  }
}))`

`;

const WrapperRight = styled(BaseWrapper).attrs(props => ({
  style: {
    left: `${props.leftPosition}px`,  // Inline style to prevent class generation
  }
}))`  
`;


const Title = styled.p`

    font-size: 12em;
    color: ${CSSGlobalVars.primaryColor};
    font-family: Tiposka;
    background-color: ${({highlight})=> highlight ? CSSGlobalVars.primaryColor : 'none' };
    color: ${({highlight})=> highlight ? '#e6f3fe' : CSSGlobalVars.primaryColor };
    align-self: ${({aligntitle})=> aligntitle ? 'flex-start' : 'flex-end'  };


    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
  
      font-size: 6em;

    }  

`


export const LogoLeft = ({ scroll }) => {
  const leftPosition = -scroll * window.innerWidth; // Calculating position based on scroll

  return (
    <WrapperLeft leftPosition={leftPosition}>
      <Title>WITH</Title>
      <Title>LOVE</Title>
    </WrapperLeft>
  );
};

export const LogoRight = ({ scroll }) => {
  const leftPosition = scroll * window.innerWidth;  // Calculating position based on scroll

  return (
    <WrapperRight leftPosition={leftPosition}>
      <Title>FROM</Title>
      <Title>AI</Title>
    </WrapperRight>
  );
};
