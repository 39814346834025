import styled from "styled-components";
import CSSGlobalVars from '../../../utils/CSSGlobalVars';
import menuImage from '../../../assets/images/header/mobile_menu.png';
import { useRef, useState } from "react";
import gsap, { Quad, Quart } from "gsap";


const Container = styled.div`
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 60px;

    display: grid;
    justify-items: center;
    //align-items: center;

    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
        height: 120px;
        grid-template-rows: 50% 50%;
    }  
`

const Wrapper = styled.div`
    background-color: ${CSSGlobalVars.primaryColor} ;
    width: 90%;
    height: 100%;
    padding-left: 10%;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-items: left;

    font-family: "IBM Plex Mono", monospace;
    font-size: 0.8em;
    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
        padding-left: 10%;
        width: 90%;
    }  
`

const MenuItems = styled.div`
    display: flex;
    justify-content: right;

    justify-self: right;
    margin-right: 20%;

`

const MenuItemsMobile = styled.div`
    background-color: #3250ed ;
    overflow: hidden;
    height: 0px;
    width: 100%;
    display: flex;
    justify-content: space-around;
`

const Title = styled.p`
    text-transform: uppercase;
    cursor: pointer;
    color: white;
`

const MenuItem = styled.p`
    text-transform: uppercase;
    margin-left: 30px;
    font-family: "IBM Plex Mono",monospace;
    cursor: pointer;
    color: white;

    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
        margin-left: 0px;
        font-size: 0.7em;
        margin-top: 20px;
    }  
`

const MobileMenuIcon = styled.img`
    justify-self: right;
    margin-right: 20%;
    width: 40px;
`

const Header = ({ onSelectArea, currentArea }) => {

    let [isHidden , setHidden] = useState(true); 


    const containerRef = useRef(null);
    const wrapperRef = useRef(null);
    const mobileMenuRef = useRef(null);
    const menuRef = useRef(null);
    const iconRef = useRef(null);

    const hideShowMobileMenu = (hide , duration)=>{
    
        setHidden(hide);
        gsap.to(mobileMenuRef.current , {duration:duration , height:hide ? '0px' : '60px' , ease:Quad.easeOut})
    } 

    const onSelect = (area)=>{
        onSelectArea(area);
        hideShowMobileMenu(true , 0.3);
    }
    

    const getMenu = () => {
        if (window.innerWidth < CSSGlobalVars.phoneCap)
            return <MobileMenuIcon ref={iconRef} src={menuImage} alt="Main Menu" onClick={()=>{
                hideShowMobileMenu(!isHidden , 0.3);
            }}></MobileMenuIcon>;

        return (
            <MenuItems ref={menuRef}>
                <MenuItem onClick={() => onSelect('gallery')}>Gallery</MenuItem>
                <MenuItem onClick={() => onSelect('about')}>About</MenuItem>
            </MenuItems>
        );
    };

    const menuMobile = ()=>{
        if (window.innerWidth > CSSGlobalVars.phoneCap)
            return;

        return(
            <MenuItemsMobile ref={mobileMenuRef}>
                <MenuItem onClick={() => onSelect('gallery')}>Gallery</MenuItem>
                <MenuItem onClick={() => onSelect('about')}>About</MenuItem>
            </MenuItemsMobile>
        )
       
    }

    return (
        <Container ref={containerRef}>
            <Wrapper ref={wrapperRef}>
                <Title onClick={() => onSelect('home')}>WITH LOVE FROM AI</Title>
                {getMenu()}
            </Wrapper>
            {menuMobile()}
        </Container>
    );
};

export default Header;
