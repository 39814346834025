import styled from "styled-components";
import gsap , {Quad} from 'gsap';
import { useEffect, useRef, useState } from "react";
import CSSGlobalVars from "../../../utils/CSSGlobalVars";
import LetterShuffler from "../common/LetterShuffler";
import DataContainer from "../common/DataContainer";
import { getRandomSystemMessage , randomFloat } from "../../../utils/Utils";


const Wrapper = styled.div`

    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 4;
    overflow: hidden;
`

const Container = styled.div`

    width: 100vw;
    height: 100vh;
    background: ${CSSGlobalVars.primaryColor};
    color: white;
    display: grid;
    grid-template-rows: 60px 30% auto;
    justify-items: left;
    align-items: center;

    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
  
        grid-template-rows: 60px 40% auto;

    }  

`

const Title = styled.p`

    text-transform: uppercase;
    color: white;
    font-family: "IBM Plex Mono", monospace;
    font-size: 14px;
    margin-left: 10%;
    
`

const Progress = styled.p`
    font-family: Tiposka;
    font-size: 12em;
    align-self: start;
    justify-self: center;

    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
  
        font-size: 5em;

    }  

`





const Loader = ({progress = 0})=>{

    const wrapper = useRef(null);
    let [hidden , setHidden] = useState(false);

    useEffect(()=>{

        const close = ()=>{

            setTimeout(() => {
                gsap.to(wrapper.current , {width:0 , duration:0.7 , ease:Quad.easeInOut});
            }, 2000);
            
            setHidden(true);
        }

        if(progress === 100 && !hidden)
          close();

    })
        


    return(
        <Wrapper ref={wrapper}>
            <Container>
                
                <Title>WITH LOVE FROM AI</Title>
                <DataContainer align={'center'} height={'200px'} textAlign={'left'} marginTop={'0%'} fontColor={'#ffffff'} marginLeft={'10%'}  justify={'space-around'}>
                    <LetterShuffler  word={getRandomSystemMessage()}  loop={true} loopInterval={randomFloat(500,2000)} startDelay={randomFloat(0,1000)} />
                    <LetterShuffler  word={getRandomSystemMessage()}  loop={true} loopInterval={randomFloat(500,2000)} startDelay={randomFloat(0,1000)} />
                    <LetterShuffler  word={getRandomSystemMessage()}  loop={true} loopInterval={randomFloat(500,2000)} startDelay={randomFloat(0,1000)} />
                    <LetterShuffler  word={getRandomSystemMessage()}  loop={true} loopInterval={randomFloat(500,2000)} startDelay={randomFloat(0,1000)} />
                    <LetterShuffler  word={getRandomSystemMessage()}  loop={true} loopInterval={randomFloat(500,2000)} startDelay={randomFloat(0,1000)} />
                    <LetterShuffler  word={getRandomSystemMessage()}  loop={true} loopInterval={randomFloat(500,2000)} startDelay={randomFloat(0,1000)} />
                </DataContainer>
                <Progress>{`${progress}%`}</Progress>
            </Container>
        </Wrapper>
    )
}

export default Loader;
