import styled from "styled-components";
import BaseWrapper from "../common/BaseArea";
import CSSGlobalVars from "../../../utils/CSSGlobalVars";
import { useEffect, useRef, useState } from "react";
import Matter from "matter-js";
import logo from '../../../assets/images/about/about_header.png';
import postacr_back from '../../../assets/images/about/postacr_back.jpg';
import postacr_front from '../../../assets/images/about/postacr_front.jpg';
import { APP_AREAS, APP_STATE, randomFloat } from "../../../utils/Utils";


const Wrapper = styled(BaseWrapper)`
    width: 100%;
    height: 140vh;
    background: white;
    padding-left: 0%;
    display: grid;
    grid-template-rows: 40% 60%;
    justify-items: center;
    align-items: center;

    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
        grid-template-rows: 20% 80%;
    }

`;

const Logo = styled.div`
    width: 100%;
    height: 100%;

    display: grid;
    justify-items: center;
    align-items: center;
    img {
        width: 60%;
        @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
            width: 90%;
        }
    }

   
`;

const PhysicsHolder = styled.div`
    display: grid;
    justify-items: center;
    width: 100%;
    height: 100%;
    position: relative; 
    overflow: hidden;

    .canvas-holder{
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
    }
`;



const Content = styled.div`
    width: 40%;
    max-width: 600px;
    border-top: 12px solid #3250ED;
    margin-top: 200px;
    padding-top: 30px;
    position: relative; /* Needed for positioning elements absolutely */
    z-index: 1; /* Bring content above the canvas */

    p {
        font-family: IBM Plex Mono;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        margin-bottom: 30px;
    }

    a {
        color: #3250ED;
    }

    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
        width: 95%;
        margin-top: 50px;
    }
`;



const About = ({ currentArea }) => {

    const canvasRef = useRef(null); 

    useEffect(() => {

        const engine =  Matter.Engine.create();
        const render = Matter.Render.create({
            element: canvasRef.current, // Attach the render to the PhysicsHolder
            engine: engine,
            options: {
                width: canvasRef.current.clientWidth,
                height: canvasRef.current.clientHeight,
                wireframes: false,
                background: 'transparent',
            },
        });

        const fixedBox = Matter.Bodies.rectangle(window.innerWidth / 2, 400 , window.innerWidth * 0.4, 400 , { isStatic: true, render: {
            fillStyle: 'transparent', 
            strokeStyle: 'transparent',
        }  });
        const ground = Matter.Bodies.rectangle(window.innerWidth / 2, canvasRef.current.clientHeight + 100, window.innerWidth, window.innerHeight * 0.2, { isStatic: true });

        Matter.World.add(engine.world, window.innerWidth > CSSGlobalVars.phoneCap ? [ground , fixedBox] : [ground]);

        const scale = window.innerWidth > CSSGlobalVars.phoneCap ? 0.7 : 0.4
        let cards = 0;

        const createFallingSquare = () => {

            const x = randomFloat(0,100) < 50 ? randomFloat(0, window.innerWidth * 0.4 ) : randomFloat(window.innerWidth * 0.6,  window.innerWidth )
            
            const y = window.innerWidth > CSSGlobalVars.phoneCap ? randomFloat(-300,-100) : window.innerHeight / 2;
            
            const imgSrc = randomFloat(0,100) > 50 ? postacr_front : postacr_back;
            
            const fallingSquare = Matter.Bodies.rectangle(x, y, 396 * scale, 280 * scale, { 
                restitution: 0.5,
                render: {
                    sprite: {
                        texture: imgSrc,
                        xScale: scale, 
                        yScale: scale,
                    },
                },
            
            
            }); 

            Matter.Body.rotate(fallingSquare, randomFloat(0,360) * Math.PI / 180);
            
            Matter.World.add(engine.world, fallingSquare);
            cards++;

            if(cards === 30)
                clearInterval(fallingInterval); 
        };

        const fallingInterval = setInterval(createFallingSquare, 600); 

        Matter.Runner.run(Matter.Runner.create(), engine);
        Matter.Render.run(render);
        
        return () => {
            clearInterval(fallingInterval);
            Matter.World.clear(engine.world);
            Matter.Engine.clear(engine);
            Matter.Render.stop(render);
        };
    }, []);


    return (
        <Wrapper>
            <Logo>
                <img src={logo} alt="Logo" />
            </Logo>
            <PhysicsHolder>
                <div className="canvas-holder" ref={canvasRef}></div>
                <Content>
                    <p>This project, created by <a href="http://www.pedrogarlaschi.com" target="_blank" rel="noreferrer">Pedro Garlaschi</a>, aims to humanize AI by highlighting its playful and helpful side, diverting attention from its more controversial aspects. Leveraging <a href="https://ai.google.dev/gemini-api" target="_blank" rel="noreferrer">Google Gemini’s</a> vision and text capabilities, we create and send unique postcards to random or provided addresses across the UK.</p>    
                    <p>Special thanks to the incredibly talented <a href="https://www.hannahthompsondesign.co.uk/" target="_blank" rel="noreferrer">Hannah Thompson</a> for her stunning design work, which forms the visual identity of this project.</p>
                </Content>
            </PhysicsHolder>
        </Wrapper>
    );
};

export default About;


//No personal data is stored, and addresses are used solely as locations.