import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ThreeContainer from './webgl/ThreeContainer';
import Header from "./header/Header";
import Home from "./home/Home";
import { getSentPostcards } from "../../utils/WebService";
import Gallery from "./gallery/Gallery";
import About from "./about/About";
import GetInvolved from "./get_involved/GetInvolved";
import Loader from "./loader/Loader";
import gsap from "gsap";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { APP_AREAS, APP_STATE, randomFloat } from "../../utils/Utils";
import Footer from "./footer/Footer";

const Container = styled.div`
    width: 110vw;
    height: 110vh;
`;

const StyledCanvas = styled.canvas`
  width: 110vw;
  height: 110vh;
  display: block;
  background: #000;
`;

const HTMLWrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const WebContainer = () => {

    let canvasRef = useRef(null);
    let wrapperRef = useRef(null);  // Ref for HTMLWrapper
    let [threeContainer, setThreeContainer] = useState(null);
    let [postcards, setPostcards] = useState([]);
    let [postcardsLoaded, setPostcardsLoaded] = useState(false);
    let [scrollPos, setScrollPos] = useState(0); 
    let [currentArea, setCurrentArea] = useState(APP_STATE.home); 
    let progress = 100;

    gsap.registerPlugin(ScrollToPlugin);

    

    const loadPostcards = async () => {
        const query = await getSentPostcards();
        setPostcards(query.postcards);
        setPostcardsLoaded(true);

    
        
        if(threeContainer)
            threeContainer.createPostcards(query.postcards);
            
    };
    
    useEffect(() => {
        if (canvasRef.current && !threeContainer) {
            setThreeContainer(new ThreeContainer(canvasRef.current))      
        }

        if(threeContainer && !postcardsLoaded)
            loadPostcards();

        if (wrapperRef.current) {
            wrapperRef.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (wrapperRef.current) {
                wrapperRef.current.removeEventListener('scroll', handleScroll);
            }
        };

    }, [postcards , threeContainer , currentArea  ]);

    const handleScroll = () => {
        
        if (wrapperRef.current) {

            const scrollNormal = (wrapperRef.current.scrollTop + 60) / (window.innerHeight * APP_AREAS.length - 1);

            const len = APP_AREAS.length;

            const cArea = APP_AREAS[Math.floor(len * scrollNormal)];
            if(cArea !== undefined && cArea !== currentArea)
                changeArea(cArea);

            setScrollPos(scrollNormal); 
                
        }
    };

    const changeArea = (area)=>
    {
        setCurrentArea(area);
        threeContainer.setAppState(area);

        switch(area){
            case APP_STATE.gallery:
                threeContainer.setCurrentPostcad(Math.round(randomFloat(0,49)) );
            break;
            default:
                break;
        }
    }

    const scrollToPosition = (position) => {
        if (wrapperRef.current) {
      
          gsap.to(wrapperRef.current, {
            scrollTo: position,
            duration: 1, 
            ease: "power2.inOut",
            
            onUpdate: () => {

                const scrollNormal = (wrapperRef.current.scrollTop + 60) / (window.innerHeight * APP_AREAS.length - 1); 
              setScrollPos(scrollNormal);
            },
      
            onComplete: () => {
            }
          });
        }
      };

    const onSelectArea = (area)=>{
        
        let pos = 0;

        switch(area){
            case APP_STATE.gallery:
                pos = 1 * window.innerHeight;
                break;
            case APP_STATE.about:
                pos = 2 * window.innerHeight;
                pos -= 60;
                break
            case APP_STATE.get_involved:
                pos = 3 * window.innerHeight;    
                break;
                default:
                    pos = 0;
                break;
        }
        //

        scrollToPosition(pos)
    }

    return (
        <Container>
            <Header onSelectArea = {onSelectArea} currentArea={currentArea} />
            <Loader progress={progress} />
            <HTMLWrapper ref={wrapperRef}>
                <Home scrollPos={scrollPos} />
                <Gallery currentArea={currentArea} postcards={postcards} />
                <About currentArea={currentArea} />
                <GetInvolved/>
                <Footer/>
            </HTMLWrapper>
            <StyledCanvas ref={canvasRef}></StyledCanvas>
        </Container>
    );
}

export default WebContainer;