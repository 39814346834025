import styled from "styled-components";
import { useState } from "react";
import { deletePostcard, getPendingPostcards, getPostcards, sendPostcard } from "../../utils/WebService";


const Wrapper = styled.div`

    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    overflow-y: scroll;

    
`


const PostcardContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'bgColor', // Prevent bgColor from being forwarded to the DOM
}).attrs((props) => ({
  style: { backgroundColor: props.bgColor || '#f3ffec' }, // Apply dynamic background color through inline styles
}))`
  width: 500px;
  height: 300px;
  display: grid;
  grid-template-columns: 40% 60%;
  justify-items: center;
  align-items: center;
  margin: 10px;
  border-radius: 5px;

  img {
    width: 90%;
  }

  .content {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 5% auto 30%;
    align-items: center;
  }

  .postcard-number {
    margin-top: 15px;
  }

  p {
    text-align: left;
  }

  .buttons {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
  }
`;


  
  

const BaseButton = styled.div.withConfig({
    shouldForwardProp: (prop) => prop !== 'enabled',
  })`
    width: 80px;
    height: 30px;
    display: grid;
    justify-content: center;
    align-content: center;
    font-size: 0.8em;
    font-weight: bold;
    color: white;
    cursor: ${(props) => (props.enabled ? 'pointer' : 'none')};
    pointer-events: ${(props) => (props.enabled ? 'auto' : 'none')};
    opacity: ${(props) => (props.enabled ? 1 : 0.5)};
  `;
  
const ButtonSend = styled(BaseButton)`
    background-color: #5b9e62;
`
const ButtonCancel = styled(BaseButton)`
    background-color: #fd5a5a;;
`



const Admin = ()=>{

    const [postcards , setPostcards] = useState([])
    const [loading , setLoading] = useState(false)

    const onGetPostcards = async ()=>{

        if(loading !== false || postcards.length !== 0)return;

        const query =  await getPostcards();
        setLoading(true);
        updatePostcards(query.postcards);
        setLoading(false);
    }

    useState(()=>{

        onGetPostcards();

    },[loading , postcards])

    const onDeletePostcard = async (id)=>{

        if(loading)return;

        setLoading(true);
        await deletePostcard(id);
        const query =  await getPostcards();
        updatePostcards(query.postcards);
        setLoading(false);

    }

    const onSendPostcard = async (id)=>{

        if(loading)return;

        setLoading(true);
        await sendPostcard(id)
        const query =  await getPostcards();
        updatePostcards(query.postcards);
        setLoading(false);

    }

    const updatePostcards = (arr) => {
        const sortedArr = arr.sort((a, b) => {
          if (a.sent === false && b.sent === true) {
            return -1;
          }
          if (a.sent === true && b.sent === false) {
            return 1;
          }
          return 0;
        });
      
        setPostcards(sortedArr);
      };
      
   

    const getPostcardComponent = ()=>{

        return postcards.map(p=>{
            return (
                <PostcardContainer bgColor={p.sent ? '#fafdff' : '#ffe2e2' } key={p.id}>
                    <img src={p.photoUrl} alt={`${p.id}`}></img>
                    <div className="content">
                        <p className="postcard-number">{p.number}</p>
                        <p>{p.message}</p>
                        <div className="buttons">
                            <ButtonSend enabled={(p.sent || loading) ? false : true} onClick={()=>{
                                onSendPostcard(p.id);
                            }} >SEND</ButtonSend>
                            <ButtonCancel enabled={(loading) ? false : true} onClick={()=>{
                                onDeletePostcard(p.id);
                            }}>DELETE</ButtonCancel>
                        </div>
                    </div>
                </PostcardContainer>
            )
        })
    }

    return(
        <Wrapper>
            {getPostcardComponent()}
        </Wrapper>
    )
}

export default Admin;
